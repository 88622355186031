import React from "react";
import styled from "@emotion/styled";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { OurCapabilitiesProps } from "../OurCapabilities";
import { Card } from "../../model/Card";
import { Row, Grid, Column } from "@ryerson/frontend.layout";
import { Link, ButtonLink } from "@ryerson/frontend.navigation";
import { Parallax } from "react-scroll-parallax";

// STYLING FOR BOTH LAYOUTS

const MoreAboutSection = styled.div`
	padding: 0px 80px 80px 0px;
`;
const ProductSectionWrapper = styled.div`
	padding: 20px 0px;
`;
const ProductImage = styled.img`
	position: absolute;
	max-width: 300px;
	max-height: 200px;
	z-index: 4;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
const ProductLabel = styled(Typography)`
	position: absolute;
	left: 30px;
	bottom: 30px;
	white-space: pre-line;
`;
const ProductSection = styled.div`
	border-radius: 2px;
	width: 100%;
	padding: 60px;
	position: relative;
	${(props: any) => {
		const { theme } = props;
		return `
			background: ${theme.colors.primary.lighterGray};
		`;
	}}
`;

// VERTICAL LAYOUT STYLING
const Left = styled.div`
	position: relative;
	width: 50%;
`;
const Right = styled.div`
	width: 50%;
	max-height: 1060px;
`;
const VCapabilitiesDescription = styled.div`
	padding: 160px 80px 40px 0px;
`;

const VProductImageSection = styled.div`
	position: relative;
	width: 200px;
	height: 200px;
	h1 {
		font-size: 240px;
		height: 240px;
		width: 100%;
		position: absolute;
		text-align: center;
		line-height: 240px;
		top: 50%;
		margin-top: -120px;
		z-index: 2;
		${(props: any) => {
			const { theme } = props;
			return `
				font-weight: ${theme.typography.fonts.weights.bold};
			`;
		}}
	}
`;
const VHeaderImageOne = styled.div`
	height: 140px;
	margin-top: 120px;
	margin-left: 0px;
	margin-bottom: 45px;
	font-size: 200px;
	line-height: 140px;
	font-weight: 500;
	letter-spacing: -12px;
	${(props: any) => {
		const { theme } = props;
		return `
			color: ${theme.colors.primary.gray};
			font-family: ${theme.typography.fontFamilies.headings};
			@media only screen and (max-width: ${theme.breakpoints.xl}) {
				font-size: 150px;
				margin-top: 180px;
				margin-left: 30px;
			}
		`;
	}}
`;
const VHeaderImageTwo = styled.div`
	font-size: 200px;
	line-height: 140px;
	margin-top: -50px;
	${process.env.GATSBY_COMPANY === "southernToolSteel"
		? "margin-left:-60px"
		: "margin-left: -160px"};
	font-weight: 500;
	letter-spacing: -12px;
	${(props: any) => {
		const { theme } = props;
		return `
			color: ${theme.colors.primary.secondaryBrand};
			font-family: ${theme.typography.fontFamilies.headings};
			@media only screen and (max-width: ${theme.breakpoints.xl}) {
				font-size: 150px;
				margin-top: -80px;
				margin-left: -80px;
			}
		`;
	}}
`;

// HORIZONTAL LAYOUT STYLING
const HCapabilitiesDescription = styled.div`
	padding: 30px 80px 40px 0px;
`;
const HProductImageSection = styled.div`
	position: relative;
	min-width: 400px;
	max-width: 560px;
	height: 200px;
	h1 {
		font-size: 240px;
		position: absolute;
		left: 50%;
		top: 40%;
		transform: translate(-50%, -30%);
		z-index: 2;
		${(props: any) => {
			const { theme } = props;
			return `
				font-weight: ${theme.typography.fonts.weights.bold};
			`;
		}}
	}
`;
const HHeaderImageOne = styled.div`
	font-size: 75px;
	line-height: 70px;
	font-weight: 500;
	letter-spacing: -2px;
	${(props: any) => {
		const { theme } = props;
		return `
			color: ${theme.colors.primary.gray};
			font-family: ${theme.typography.fontFamilies.headings};
		`;
	}}
`;
const HHeaderImageTwo = styled.div`
	font-size: 75px;
	line-height: 70px;
	font-weight: 500;
	letter-spacing: -2px;
	${(props: any) => {
		const { theme } = props;
		return `
			color: ${theme.colors.primary.secondaryBrand};
			font-family: ${theme.typography.fontFamilies.headings};
			@media only screen and (max-width: ${theme.breakpoints.xl}) {
				font-size: 75px;
			}
		`;
	}}
`;
const margins = css`
	margin: 75px 0;
`;
const rightMargins = css`
	margin-right: 40px;
`;
const CardContainer = styled.div`
	width: 100%;
	max-width: 100%;
	position: absolute;
	left: 0;
	overflow: hidden;
`;

const OurCapabilitiesDesktop: React.FC<OurCapabilitiesProps> = ({ ...props }) => {
	const { theme } = useTheme();

	const productSection = css`
		background: ${theme.colors.primary.lighterGray};
		border-radius: 2px;
		width: 100%;
		padding: 60px;
		position: relative;
	`;

	return (
		<ContentSection vPadding="40px">
			{props.vertical ? (
				<>
					<Flex>
						<Left>
							<VHeaderImageOne theme={theme}>
								{props.content?.backgroundText1}
							</VHeaderImageOne>
							<VHeaderImageTwo theme={theme}>
								{props.content?.backgroundText2}
							</VHeaderImageTwo>

							<VCapabilitiesDescription>
								<Typography variant="div">{props.content?.context}</Typography>
							</VCapabilitiesDescription>

							<MoreAboutSection>
								<ButtonLink
									to={props.content?.actionLink ?? "#"}
									label={props.content?.actionLabel ?? ""}
									gatsby={true}
								/>
							</MoreAboutSection>
						</Left>
						<Right>
							<Parallax y={[100, -100]}>
								{props.content?.cards.map((card: Card, index: number) => (
									<Link to={card.actionUrl ?? "#"}>
										<ProductSectionWrapper key={index}>
											<Flex justifyContent="center" css={productSection}>
												<ProductLabel
													weight="bold"
													color={theme.colors.primary.secondaryBrand}
													size="lg"
												>
													{card.text}
												</ProductLabel>
												<VProductImageSection theme={theme}>
													<ProductImage src={card.imageUrl} />
												</VProductImageSection>
											</Flex>
										</ProductSectionWrapper>
									</Link>
								))}
							</Parallax>
						</Right>
					</Flex>
				</>
			) : (
				<>
					<Grid>
						<Row>
							<Column md={6}>
								<HHeaderImageOne theme={theme}>
									{props.content?.backgroundText1}
								</HHeaderImageOne>
								<HHeaderImageTwo theme={theme}>
									{props.content?.backgroundText2}
								</HHeaderImageTwo>
							</Column>
							<Column md={6}>
								<HCapabilitiesDescription>
									<Typography variant="div" size="md">
										{props.content?.context}
									</Typography>
								</HCapabilitiesDescription>
							</Column>
						</Row>
					</Grid>
					<CardContainer>
						<Parallax x={[-100, 100]}>
							<Flex css={margins}>
								{props.content?.cards.map((card: Card, index: number) => (
									<Link to={card.actionUrl ?? "#"}>
										<FlexItem css={rightMargins} key={index}>
											<ProductSectionWrapper>
												<ProductSection theme={theme}>
													<ProductLabel
														weight="bold"
														color={theme.colors.primary.secondaryBrand}
														size="lg"
													>
														{card.text}
													</ProductLabel>
													<HProductImageSection theme={theme}>
														<ProductImage src={card.imageUrl} />
													</HProductImageSection>
												</ProductSection>
											</ProductSectionWrapper>
										</FlexItem>
									</Link>
								))}
							</Flex>
						</Parallax>
					</CardContainer>
					<MoreAboutSection
						css={css`
							margin-top: 500px;
						`}
					>
						<ButtonLink
							to={props.content?.actionLink ?? "#"}
							label={props.content?.actionLabel ?? ""}
							gatsby={true}
						/>
					</MoreAboutSection>
				</>
			)}
		</ContentSection>
	);
};

export default OurCapabilitiesDesktop;
